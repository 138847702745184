var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nodata" }, [
    _c("img", {
      staticClass: "empty",
      attrs: { src: require(`../../../../assets/ico/empty_data.png`) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }