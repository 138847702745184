<template>
  <el-dialog :visible.sync="status" :show-close="false" custom-class="tips">
    <i class="el-icon--check-solid"></i>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      status: false
    };
  },
  props: {
    showTips: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    watchTip() {
      return this.showTips;
    }
  },
  watch: {
    watchTip: {
      handler(val) {
        this.status = val;
      },
      dreep: true
    }
  },

  created() {
    this.status = this.showTips;
  }
};
</script>
<style lang="stylus">
.tips {
  width: 70px !important;
  height: 70px;

  .el-dialog__body, .el-dialog__header {
    padding: 0;
    margin: 0;
  }

  .el-dialog__body {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .el-icon--check-solid {
      font-size: 2rem;
      color: #33cc66;
    }
  }
}
</style>
