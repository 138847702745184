var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.status,
        "show-close": false,
        "custom-class": "tips"
      },
      on: {
        "update:visible": function($event) {
          _vm.status = $event
        }
      }
    },
    [_c("i", { staticClass: "el-icon--check-solid" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }