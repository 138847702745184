var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item" },
    [
      _vm.item.avatar
        ? _c("img", {
            class: { small: _vm.small, thumb: true },
            attrs: { src: _vm.formartImageUrl(_vm.item.avatar) },
            on: {
              click: function($event) {
                return _vm.jumpPage({
                  name: "detail",
                  params: { id: _vm.item.id, type: _vm.type }
                })
              }
            }
          })
        : _c(
            "i",
            {
              class: { small: _vm.small, thumb: true },
              on: {
                click: function($event) {
                  return _vm.jumpPage({
                    name: "detail",
                    params: { id: _vm.item.id, type: _vm.type }
                  })
                }
              }
            },
            [_vm._v("Mellinked\n  ")]
          ),
      _c("span", {
        staticClass: "title",
        domProps: {
          innerHTML: _vm._s(
            _vm.lightHeight
              ? _vm.highlighted(_vm.item.name, _vm.keyword)
              : _vm.item.name
          )
        },
        on: {
          click: function($event) {
            return _vm.jumpPage({
              name: "detail",
              params: { id: _vm.item.id, type: _vm.type }
            })
          }
        }
      }),
      _vm.item.tags.length
        ? _c(
            "ul",
            { staticClass: "tags" },
            _vm._l(_vm.item.tags, function(tag, key) {
              return _c("li", {
                key: key,
                domProps: {
                  innerHTML: _vm._s(
                    _vm.lightHeight
                      ? _vm.highlighted(
                          tag.length > 16 ? tag.slice(0, 16) + "..." : tag,
                          _vm.keyword
                        )
                      : tag.length > 16
                      ? tag.slice(0, 16) + "..."
                      : tag
                  )
                }
              })
            }),
            0
          )
        : _vm._e(),
      _c("div", {
        staticClass: "desc",
        domProps: {
          innerHTML: _vm._s(
            _vm.lightHeight
              ? _vm.highlighted(
                  _vm.item.desc.length > 36
                    ? _vm.item.desc.slice(0, 36) + "..."
                    : _vm.item.desc,
                  _vm.keyword
                )
              : _vm.item.desc.length > 36
              ? _vm.item.desc.slice(0, 36) + "..."
              : _vm.item.desc
          )
        }
      }),
      _vm.showAction
        ? _c("actions", {
            staticClass: "action",
            attrs: { "action-data": _vm.item.actions }
          })
        : _vm._e(),
      _vm.edit && _vm.item.owner === _vm.uid
        ? _c("edit", {
            attrs: { params: _vm.params, needEdit: true },
            on: { delSuccess: _vm.delHandler }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }