<template>
  <div class="item">
    <img
      :class="{ small: small, thumb: true }"
      v-if="item.avatar"
      :src="formartImageUrl(item.avatar)"
      @click="jumpPage({ name: 'detail', params: { id: item.id, type } })"
    />
    <i
      :class="{ small: small, thumb: true }"
      v-else
      @click="jumpPage({ name: 'detail', params: { id: item.id, type } })"
      >Mellinked
    </i>
    <span
      class="title"
      v-html="lightHeight ? highlighted(item.name, keyword) : item.name"
      @click="jumpPage({ name: 'detail', params: { id: item.id, type } })"
    ></span>
    <ul class="tags" v-if="item.tags.length">
      <li
        v-for="(tag, key) in item.tags"
        :key="key"
        v-html="
          lightHeight
            ? highlighted(
                tag.length > 16 ? tag.slice(0, 16) + '...' : tag,
                keyword
              )
            : tag.length > 16
            ? tag.slice(0, 16) + '...'
            : tag
        "
      ></li>
    </ul>
    <div
      class="desc"
      v-html="
        lightHeight
          ? highlighted(
              item.desc.length > 36
                ? item.desc.slice(0, 36) + '...'
                : item.desc,
              keyword
            )
          : item.desc.length > 36
          ? item.desc.slice(0, 36) + '...'
          : item.desc
      "
    ></div>
    <actions v-if="showAction" :action-data="item.actions" class="action" />
    <edit
      v-if="edit && item.owner === uid"
      :params="params"
      @delSuccess="delHandler"
      :needEdit="true"
    />
  </div>
</template>
<script>
import actions from "../action";
import edit from "../action/edit";
export default {
  data() {
    return {
      params: {},
      uid: ""
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    },
    small: {
      type: Boolean,
      default: false
    },
    showAction: {
      type: Boolean,
      default: true
    },
    edit: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "1"
    },
    lightHeight: {
      type: Boolean,
      default: false
    },
    keyword: {
      type: String,
      default: ""
    }
  },
  components: {
    actions,
    edit
  },
  created() {
    // console.log(this.item, "///item");
    this.uid = this.$store.getters.userInfo.id;
    this.params = {
      type: this.type,
      id: this.item.id
    };
  },
  methods: {
    delHandler() {
      this.$emit("delSuccess");
    }
  }
};
</script>
<style lang="stylus" scoped>
.item {
  background: #ffffff;
  margin: 0.5% 0.5% 0.5% 0;
  // min-width: 232px;
  width: 19.6%;
  padding: 0.5rem 0.4%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  &:nth-child(5n) {
    margin-right: 0;
  }

  .action {
    position: absolute;
    bottom: 6px;
    right: 0px;
  }

  .thumb {
    width: 100%;
    height: 13rem;
    object-fit: cover;
    background: #eeeeee;
    display: block;
    border-radius: 3px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    font-style: normal;
    line-height: 90px;
    text-align: center;
    color: #ffffff;
    font-weight: 800;
    font-size: 2.5rem;

    &.small {
      height: 10rem;
    }
  }

  .title {
    font-size: 14px;
    color: #222222;
    margin: 0.5rem 0 0;
    font-weight: bold;
    height: 20px;
    overflow: hidden;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }

  .tags {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    margin: 0.5rem 0;
    height: 28px;
    overflow: hidden;

    li {
      border: 1px solid #33cc66;
      margin: 0.15rem;
      border-radius: 5rem;
      padding: 0 0.5rem;
      font-size: 12px;
      height: 24px;
      line-height: 24px;
      overflow: hidden;
    }
  }

  .desc {
    font-size: 13px;
    line-height: 22px;
    // height: 45px;
    margin: 0;
    overflow: hidden;
    padding-bottom: 20px;

    p {
      margin: 0;
      padding: 0;
      display: inline;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
}
</style>
