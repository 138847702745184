var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "btns" },
    [
      !_vm.isDelete
        ? _c("i", {
            staticClass: "el-icon-refresh-left",
            on: {
              click: function($event) {
                return _vm.refreshFunc($event)
              }
            }
          })
        : _vm._e(),
      _c("i", {
        staticClass: "el-icon-delete",
        on: {
          click: function($event) {
            return _vm.delFunc($event)
          }
        }
      }),
      !_vm.isDelete && _vm.needEdit
        ? _c("i", {
            staticClass: "el-icon-edit",
            on: {
              click: $event => {
                _vm.jumpToPage($event)
              }
            }
          })
        : _vm._e(),
      _vm.showTips
        ? _c("tips", { attrs: { "show-tips": _vm.showTips } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }