<template>
  <span class="btns">
    <i v-if="!isDelete" class="el-icon-refresh-left" @click="refreshFunc($event)"></i>
    <i class="el-icon-delete" @click="delFunc($event)"></i>
    <i
      class="el-icon-edit"
       v-if="!isDelete && needEdit"
      @click="($event)=>{jumpToPage($event)}"
    ></i>
    <tips v-if="showTips" :show-tips="showTips"></tips>
  </span>
</template>
<script>
import tips from "@/components/business/web-site/dialog/tip";
// import { type } from "os";
export default {
  name: "EditComponent",
  components: {
    tips
  },
  data() {
    return {
      lang: 1,
      uid: "",
      type: 1,
      showTips: false
    };
  },
  props: {
    params: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 是否被后台删除了，如果是，则不能再编辑或者刷新帖子
    isDelete: {
      type: Boolean,
      default: false
    },
    // 是否需要编辑按钮
    needEdit: {
      type: Boolean,
      default: false
    },
    // 判断是不是来修改帖子的，是的话，修改跳转到新发布页
    isEditPost: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    watchType() {
      return this.type;
    }
  },
  watch: {
    watchType: {
      handler(val) {
        this.formartType(val);
      },
      dreep: true
    }
  },
  created() {
    this.type = this.params.type || 1;
    this.formartType(this.type);
    this.lang = localStorage.getItem("langCode") || 1;
    this.uid = this.$store.getters.userInfo.id;
  },
  methods: {
    // 跳转到发布页面重新发布一次，相当于以前的编辑
    jumpToPage(event) {
      if(this.params.id && this.params.id !== '' && this.isEditPost) {
        this.routeJump({ name: "newPublish" , params: { id: this.params.id } });
      }else {
        // 兼容之前的群修改和企业信息修改
        this.jumpPage({ name: 'publish', params: this.params }, event)
      }
    },
    formartType(type) {
      switch (parseInt(type)) {
        case 1:
          this.params.type = "service";
          break;
        case 4:
          this.params.type = "group";
          break;
        case 5:
          this.params.type = "company";
          break;
      }
    },
    delFunc(event) {
      switch (parseInt(this.type)) {
        case 4:
          this.confirm(null, null, "el-icon-delete", this.delGroup);
          break;
        case 5:
          this.confirm(null, null, "el-icon-delete", this.delEnter);
          break;
        case 1:
          this.confirm(null, null, "el-icon-delete", this.delService);
          break;
      }
      event.stopPropagation();
    },
    async refreshFunc(event) {
      event.stopPropagation();
      let params = {
        type: this.type,
        userId: this.uid
      };
      let result = await this.$http({
        url: "rest/home/v2/refresh/" + this.params.id,
        params,
        method: "post",
        withCredentials: false
      }).catch(e => {
        console.log(e + "from edit.vue");
      });
      if (result) {
        this.addTips();
      }
    },
    async delService() {
      let params = {
        language: this.lang,
        helogigId: this.params.id,
        userId: this.uid
      };

      let result = await this.$http({
        url: "rest/helogig/v3/deleteHelogig",
        method: "post",
        params,
        withCredentials: false
      }).catch(e => {
        console.log(e + "from edit.vue");
      });
      if (result) this.addTips();
      this.$emit("delSuccess");
    },
    async delEnter() {
      let params = {
        language: this.lang,
        enterpriseId: this.params.id,
        providerId: this.uid
      };
      let result = await this.$http({
        url: "rest/helogig/v3/deleteEnterprise",
        method: "post",
        params,
        withCredentials: false
      }).catch(e => {
        console.log(e + "from edit.vue");
      });
      if (result) this.addTips();
      this.$emit("delSuccess");
    },
    addTips() {
      this.showTips = !this.showTips;
      // if (this.showTips) {
      setTimeout(() => {
        this.showTips = !this.showTips;
      }, 2000);
      // }
    },
    async delGroup() {
      let params = {
        language: this.lang,
        groupId: this.params.id,
        userId: this.uid
      };
      let result = await this.$http({
        url: "rest/helogig/v3/deleteGroup",
        method: "post",
        params,
        withCredentials: false
      }).catch(e => {
        console.log(e + "from edit.vue");
      });
      if (result) this.addTips();
      this.$emit("delSuccess");
    }
  }
};
</script>
<style lang="stylus" scoped>
.btns {
  position: absolute;
  right: 0.1rem;
  bottom: 0.2rem;

  i {
    font-size: 16px;
    color: #666666;
    margin: 0 0.7rem;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
